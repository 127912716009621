import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useAttachements() {
  const { errorToast, successToast } = useAlertNotification()
  const attachement = ref([])
  const loader = ref(false)
  const attachementProcess = ref(false)
  const attachementSuccess = ref(false)
  const attachements = ref([])
  const attachementsValide = ref([])
  const attachementsEnvoye = ref([])
  const attachementsRejete = ref([])
  const errors = ref('')

  // Liste des pieces
  const listAttachementsByRequestCertificate = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-liste-attachement-by-request-certificate/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        attachements.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Liste des pieces par mairie par statut
  const listAttachementsByMairieByStatus = async status => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-list-attachement-bymairie-bystatus/${status}`)
      if (response.data.success === true) {
        loader.value = false
        attachements.value = response.data.data
        if (status === 'Validé') attachementsValide.value = response.data.data
        else if (status === 'Envoyé') attachementsEnvoye.value = response.data.data
        else if (status === 'Rejeté') attachementsRejete.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const changerStatusAttachement = async data => {
    try {
      errors.value = ''
      attachementProcess.value = true
      const response = await axiosClient.patch(`/change-status-attachement/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Changement de status', response.data.message)
        attachementSuccess.value = true
        attachementProcess.value = false
        await listAttachementsByRequestCertificate()
      }
    } catch (error) {
      attachementProcess.value = false
      attachementSuccess.value = false
      loader.value = false
      errors.value = ''
      if (error.response.status === 401) errorToast('Oups! Erreur', error.response.data.message)
      else {
        errors.value = error.response.data.errors
        const responseErrors = error.response.data.errors
        Object.keys(responseErrors).forEach(key => {
          errorToast('Oups! Erreur', responseErrors[key][0])
        })
      }
    }
  }
  // Ajouter une pièce
  const createOrUpdateAttachement = async data => {
    try {
      errors.value = ''
      attachementProcess.value = true
      const response = await axiosClient.post('/store-or-update-attachement', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        attachementSuccess.value = true
        attachementProcess.value = false
        await listAttachementsByRequestCertificate()
      }
    } catch (error) {
      attachementProcess.value = false
      attachementSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorToast('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  return {
    createOrUpdateAttachement,
    listAttachementsByRequestCertificate,
    changerStatusAttachement,
    attachement,
    attachementProcess,
    loader,
    attachementSuccess,
    attachements,
    listAttachementsByMairieByStatus,
    attachementsRejete,
    attachementsEnvoye,
    attachementsValide,
    errors,
  }
}
