<template>
  <b-row>
    <b-col md="12">
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mb-1"
            onclick="history.back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" />
            Retour
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-row>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations sur la demande</span>

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Document demandé</span>
                      {{ requestCertificate.type_certificat.name }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Etat de la demande</span>
                      <b-badge
                        pill
                        :variant="`${resolveRequestCertificateStatusVariant(requestCertificate.status)}`"
                      >
                        {{ requestCertificate.status }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Date de la demande</span>
                      {{ requestCertificate.created_at }}

                    </b-list-group-item>

                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations de l'impétrant</span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Nom de l'impétrant</span>
                      {{ requestCertificate.demandeur.name }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>NPI</span>
                      {{ requestCertificate.demandeur.npi }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Numéro de téléphone</span>
                      {{ requestCertificate.demandeur.phonenumber }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Adresse e-mail</span>
                      {{ requestCertificate.demandeur.email }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>

          </b-row>
        </b-col>
        <b-col md="7">
          <b-row>
            <b-col md="12">
              <b-card title="Suivi du processus de validation">
                <ul class="progress_bar pb-5">
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${requestCertificate.created_at}`"
                    :class="requestCertificate.status === 'Soumis' || requestCertificate.status === 'En cours' || requestCertificate.status === 'Traité' || requestCertificate.status === 'Rejeté' ?'active':''"
                  >
                    Soumise
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${requestCertificate.date_encours}`"
                    :class="requestCertificate.status === 'En cours' || requestCertificate.status ==='Traité'|| requestCertificate.status ==='Rejeté' ?'active':''"
                  >
                    En cours
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${processCertificateVerification.date_validee}`"
                    :class="processCertificateVerification.status === 'Validé' || requestCertificate.status === 'Traité' ? 'active' : ''"
                  >
                    Vérification
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${processCertificateSignature.date_validee}`"
                    :class="processCertificateSignature.status === 'Validé' || requestCertificate.status === 'Traité' ? 'active' : ''"
                  >
                    Signature
                  </li>
                </ul>
                <b-card-body />
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card title="Pièces fournies">
                <b-card-body>
                  <b-table
                    striped
                    hover
                    size="sm"
                    responsive
                    :busy="loader"
                    class="position-relative"
                    :items="items"
                    :fields="fields"
                  >
                    <template #table-busy>
                      <div class="d-flex justify-content-center mb-3">
                        <b-spinner
                          style="width: 2rem; height: 2rem;"
                          variant="primary"
                          label="Large Spinner"
                          type="grow"
                          class="mt-2"
                        />
                        <span class="sr-only">Chargement en cours...</span>
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        pill
                        :variant="`light-${resolvePieceStatusVariant(data.item.status)}`"
                      >
                        {{ data.item.status }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-tooltip.hover.bottom="'Voir la pièce'"
                          v-b-modal.modal-display
                          variant="dark"
                          size="sm"
                          class="btn-icon rounded-circle ml-1"
                          @click.prevent="getPdfFile(data.item)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button
                          v-if="data.item.status === 'Rejeté'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-tooltip.hover.bottom="'Modifier la pièce'"
                          v-b-modal.update-piece
                          variant="primary"
                          size="sm"
                          class="btn-icon rounded-circle ml-1"
                          @click.prevent="getPdfFile(data.item)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>

                      </div>
                    </template>

                  </b-table>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-col>
    <!-- modal -->
    <b-modal
      id="update-piece"
      ref="my-modal"
      no-close-on-backdrop
      button-size="sm"
      title="Modifier une pièce jointe"
      ok-title="Modifier"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handlePieceOk"
    >
      <!-- @show="clearPiece" -->
      <validation-observer ref="accountRules">
        <form @submit.prevent="handleSubmitPieceModal">
          <b-form-group
            label="Numéro de la pièce"
            label-for="piece_number"
          >
            <validation-provider
              #default="{ errors }"
              name="numéro de pièce"
              rules="required"
            >
              <b-form-input
                id="piece_number"
                v-model="formPiece.numero_piece"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Numéro de la pièce"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Pièce"
            label-for="fichier"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="pièce"
              rules="required"
            >
              <b-form-file
                id="fichier"
                ref="file-input"
                v-model="formPiece.filename"
                accept=".pdf"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-display"
      ref="my-modal"
      size="xl"
      button-size="sm"
      title="Pièce jointe"
      ok-only
      ok-title="Fermer"
    >
      <b-card style="height: 500px;">
        <iframe
          style="height: 500px; width: 100%;"
          :src="PDFURL"
        />
      </b-card>
    </b-modal>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  onBeforeMount, reactive, ref,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BBadge, BTable, BButton, BCardBody, BSpinner, VBTooltip, BListGroup, BListGroupItem, BModal,
  BFormGroup, BFormInput, BFormFile,

} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// eslint-disable-next-line import/no-cycle
import useRequestCertificates from '@/services/requestCertificate/requestCertificate'
// eslint-disable-next-line import/no-cycle
import useProcessCertificates from '@/services/agent/processCertificateService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import useAttachements from '@/services/agent/attachementService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BButton,
    BCardBody,
    BBadge,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormGroup,
    BFormInput,
    BFormFile,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      findProcessCertificateVerification, findProcessCertificateSignature, processCertificateVerification, processCertificateSignature,
    } = useProcessCertificates()
    const {
      requestCertificate, loader, findRequestCertificateById,
    } = useRequestCertificates()
    const {
      createOrUpdateAttachement, attachementSuccess, attachements, listAttachementsByRequestCertificate,
    } = useAttachements()
    const {
      imageFile,
      handleImageSelected,
      uploadFilePdf,
      fileName,
      deleteFile,
    } = useImageUpload()
    const fields = [
      { key: 'document_type.label', label: 'Pièce', sortable: true },
      { key: 'status', label: 'Etat', sortable: true },
      { key: 'numero_piece', label: 'N°Pièce', sortable: true },
      { key: 'commentaire', label: 'Commentaire', sortable: true },
      { key: 'actions', label: 'Actions', sortable: true },
    ]
    const formPiece = reactive({
      numero_piece: '',
      id: null,
      document_type_id: '',
      request_certificate_id: '',
      filename: null,
    })
    const items = ref([])

    onBeforeMount(async () => {
      await findRequestCertificateById()
      await findProcessCertificateVerification()
      await findProcessCertificateSignature()
      await listAttachementsByRequestCertificate()
      items.value = attachements.value
      console.clear()
    })
    const resolvePieceStatusVariant = status => {
      if (status === 'Envoyé') return 'warning'
      if (status === 'Validé') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const resolveRequestCertificateStatusVariant = status => {
      if (status === 'Soumis') return 'primary'
      if (status === 'En cours') return 'warning'
      if (status === 'Traité') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const oldImage = ref('')
    const PDFURL = ref('')
    const getPdfFile = item => {
      PDFURL.value = ''
      PDFURL.value = item.pdf_url
      formPiece.numero_piece = item.numero_piece
      // formPiece.filename = item.filename
      formPiece.id = item.id
      formPiece.document_type_id = item.document_type_id
      formPiece.request_certificate_id = item.request_certificate_id
      oldImage.value = item.filename
    }
    const upload = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await uploadFilePdf(data)
        formPiece.filename = fileName.value
        await deleteFile(oldImage.value)
      }
    }

    const clearPiece = () => {
      formPiece.numero_piece = ''
      formPiece.filename = ''
      formPiece.id = null
      formPiece.document_type_id = null
      formPiece.request_certificate_id = null
      oldImage.value = ''
    }

    const updatePiece = async () => {
      await upload()
      await createOrUpdateAttachement({ ...formPiece })
      if (attachementSuccess.value) clearPiece()
      items.value = attachements.value
    }

    const formatter = value => value.toUpperCase()
    return {
      requestCertificate,
      processCertificateVerification,
      processCertificateSignature,
      formatter,
      loader,
      updatePiece,
      required,
      PDFURL,
      items,
      handleImageSelected,
      getPdfFile,
      resolveRequestCertificateStatusVariant,
      resolvePieceStatusVariant,
      fields,
      formPiece,
    }
  },
  methods: {
    handlePieceOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitPieceModal()
    },
    handleSubmitPieceModal() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(async success => {
          if (success) {
            resolve(true)
            await this.updatePiece()
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          } else {
            reject()
          }
        })
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.progress_bar {

  display: flex;
  counter-reset: circle 0;
}

.progress_bar li {
  list-style: none;
  margin-right: 55px;
  counter-increment: circle 1;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.progress_bar li::before {
  content: counter(circle);
  position: absolute;
  top: 30px;
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 120px;
  background-color: #f6f6f6;
  top: 48px;
  right: 37px;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li:nth-child(1)::after {
  display: none;
}

.progress_bar li.active::before {
  background-color: #005aa4 !important;
  color: #f6f6f6 !important;
}

.progress_bar li.active::after {
  background-color: #005aa4 !important;
}
</style>
<style scoped>
.progress_bar {

  display: flex;
  counter-reset: circle 0;
}

.progress_bar li {
  list-style: none;
  margin-right: 55px;
  counter-increment: circle 1;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.progress_bar li::before {
  content: counter(circle);
  position: absolute;
  top: 30px;
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 120px;
  background-color: #f6f6f6;
  top: 48px;
  right: 37px;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li:nth-child(1)::after {
  display: none;
}

.progress_bar li.active::before {
  background-color: #005aa4 !important;
  color: #f6f6f6 !important;
}

.progress_bar li.active::after {
  background-color: #005aa4 !important;
}
</style>
